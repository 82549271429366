import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Site, File } from '../../schema';
import { Helmet } from 'react-helmet';
import { isUrlAbsolute } from '../../utils/UrlHelpers';

export default class SEO extends Component<SEOProps> {
  static defaultProps: SEODefaultProps = {
    lang: 'en',
    locale: 'en_AU',
    link: [],
    meta: [],
    keywords: [],
    fbAppId: '',
    titleTemplate: '{pageTitle} – {siteTitle}',
    twitterCardType: 'summary_large_image',
    twitterCreator: '@JaWoodle',
    twitterSite: '@JaWoodle',
  };

  render() {
    return <StaticQuery query={query} render={this.renderWithResults} />;
  }

  renderWithResults = (data: SEOQueryResult): ReactNode => {
    const {
      lang,
      locale,
      link,
      meta,
      keywords,
      title,
      titleTemplate,
      socialTitle,
      description,
      slug,
      socialShareImage,
      fbAppId,
      twitterCardType,
      twitterSite,
      twitterCreator,
    } = this.props as SEODefaultProps & SEOProps;

    const { siteMetadata } = data.site;
    const { defaultSocialShareImage } = data;

    const siteUrl = siteMetadata?.siteUrl;
    const pathPrefix = siteMetadata?.pathPrefix || '';

    // Calculate Resolved Title
    const siteTitle = data.site.siteMetadata?.title || '';
    const resolvedTitle = titleTemplate
      .replace('{siteTitle}', siteTitle)
      .replace('{pageTitle}', title);
    const resolvedSocialTitle = socialTitle || resolvedTitle;

    // Calculate Resolved Description
    const resolvedDescription = description || siteMetadata?.description || '';

    // Setup default <link> and <meta> arrays
    const defaultLinkTags = [] as LinkProps[];
    const defaultMetaTags = [] as MetaProps[];

    // Push Title and Description
    defaultMetaTags.push({
      name: `description`,
      content: resolvedDescription,
    });
    defaultMetaTags.push({
      property: `og:title`,
      content: resolvedSocialTitle,
    });
    defaultMetaTags.push({
      property: `og:description`,
      content: resolvedDescription,
    });
    defaultMetaTags.push({
      property: `og:type`,
      content: `website`,
    });
    defaultMetaTags.push({
      property: `og:locale`,
      content: locale,
    });
    defaultMetaTags.push({
      property: `og:site_name`,
      content: siteTitle,
    });
    defaultMetaTags.push({
      property: `fb:app_id`,
      content: fbAppId,
    });
    defaultMetaTags.push({
      name: `twitter:title`,
      content: resolvedSocialTitle,
    });
    defaultMetaTags.push({
      name: `twitter:description`,
      content: resolvedDescription,
    });

    // Push default Twitter <meta>
    defaultMetaTags.push({
      name: `twitter:card`,
      content: twitterCardType,
    });
    defaultMetaTags.push({
      name: `twitter:creator`,
      content: twitterCreator,
    });
    defaultMetaTags.push({
      name: `twitter:site`,
      content: twitterSite,
    });

    // Facebook & Twitter Image
    const resolvedSocialShareImage:
      | string
      | IGatsbyImageData
      | null
      | undefined =
      socialShareImage ||
      defaultSocialShareImage.childImageSharp?.gatsbyImageData;
    if (resolvedSocialShareImage) {
      let socialShareImageUrl =
        typeof resolvedSocialShareImage === 'string'
          ? resolvedSocialShareImage
          : resolvedSocialShareImage.images.fallback?.src!;

      if (!isUrlAbsolute(socialShareImageUrl) && siteUrl) {
        socialShareImageUrl = siteUrl + socialShareImageUrl;
      }

      defaultMetaTags.push({
        property: `og:image`,
        content: socialShareImageUrl,
      });

      if (typeof resolvedSocialShareImage !== 'string') {
        if (resolvedSocialShareImage.width) {
          defaultMetaTags.push({
            property: `og:image:width`,
            content: `${resolvedSocialShareImage.width}`,
          });
        }

        if (resolvedSocialShareImage.height) {
          defaultMetaTags.push({
            property: `og:image:height`,
            content: `${resolvedSocialShareImage.height}`,
          });
        }
      }

      defaultMetaTags.push({
        name: `twitter:image`,
        content: socialShareImageUrl,
      });
    }

    // Calculate & Push Canonical URL
    let canonicalUrl: string | undefined = undefined;
    if (siteUrl && slug) {
      canonicalUrl = siteUrl + pathPrefix + slug;

      defaultMetaTags.push({
        property: `og:url`,
        content: canonicalUrl,
      });
    }

    // Keywords
    if (keywords.length > 0) {
      defaultMetaTags.push({
        name: `keywords`,
        content: keywords.join(`, `),
      });
    }

    // Push CI version
    if (siteMetadata?.commitSha) {
      defaultMetaTags.push({
        name: `ci:deployed-version`,
        content: siteMetadata?.commitSha,
      });
    }

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={resolvedTitle}
        link={defaultLinkTags.concat(link)}
        meta={defaultMetaTags.concat(meta)}
      />
    );
  };
}

interface SEODefaultProps {
  lang: string;
  locale: string;
  link: LinkProps[];
  meta: MetaProps[];
  keywords: string[];
  titleTemplate: string;
  fbAppId: string;
  twitterCardType: 'summary' | 'summary_large_image';
  twitterCreator: string;
  twitterSite: string;
}

export interface SEOProps extends Partial<SEODefaultProps> {
  description?: string;
  title: string;
  slug?: string;
  socialTitle?: string;
  socialShareImage?: string | IGatsbyImageData;
}

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        pathPrefix
        commitSha
        title
        description
      }
    }
    defaultSocialShareImage: file(
      relativePath: { eq: "images/default-social-share.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          height: 630
          quality: 90
          formats: [JPG]
          backgroundColor: "rgba(255,255,255,1)"
          placeholder: NONE
          transformOptions: { cropFocus: ATTENTION }
          layout: FIXED
        )
      }
    }
  }
`;

interface SEOQueryResult {
  site: Site;
  defaultSocialShareImage: File;
}

type LinkProps = JSX.IntrinsicElements['link'];
type MetaProps = JSX.IntrinsicElements['meta'];
