import { WindowLocation } from '@reach/router';
import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { File } from '../../schema';
import { DuringAnimationFrame } from '../../utils/BrowserHelpers';

export default class Layout extends Component<
  PropsWithChildren<LayoutProps>,
  LayoutState
> {
  static defaultProps: LayoutDefaultProps = {
    useVideoBg: true,
  };

  private videoRef = React.createRef<HTMLVideoElement>();

  constructor(
    props:
      | Readonly<React.PropsWithChildren<LayoutProps>>
      | React.PropsWithChildren<LayoutProps>
  ) {
    super(props);
    this.state = {
      autoplaySupported: true,
    };
  }

  componentDidMount() {
    const { useVideoBg } = this.props;

    if (useVideoBg) {
      const callback = DuringAnimationFrame(() => {
        if (this.videoRef.current && this.videoRef.current.paused) {
          this.videoRef.current.play().then(
            () => {
              return;
            },
            () => {
              this.setState({
                autoplaySupported: false,
              });
            }
          );
        }
      });

      callback();
    }
  }

  render() {
    return <StaticQuery query={query} render={this.renderWithResults} />;
  }

  renderWithResults = (queryResults: LayoutQueryResults): ReactNode => {
    const { useVideoBg, children } = this.props;
    const { autoplaySupported } = this.state;

    const pageLayoutContent = (
      <div className='relative flex flex-col h-full w-full z-10'>
        <div className='relative flex flex-col h-full w-full items-center justify-center overflow-y-auto z-10'>
          <main className='flex-none py-4 px-6 max-h-full'>{children}</main>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class:
              'font-sans antialiased bg-brand-jawoodle text-gray-100 overflow-hidden overscroll-x-none overscroll-none',
          }}
        />
        {pageLayoutContent}
        {useVideoBg ? (
          <div className='fixed z-0 inset-0' role='presentation'>
            {autoplaySupported ? (
              <video
                ref={this.videoRef}
                className='absolute inset-0 w-full h-full object-cover filter saturate-50'
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline={true}
                muted={true}
                poster={
                  queryResults.posterImage.childImageSharp!.gatsbyImageData
                    .images.fallback.src!
                }
              >
                <source
                  src={queryResults.videoWebm_480p.publicURL!}
                  type='video/webm'
                  media='all and (max-width: 852px)'
                />
                <source
                  src={queryResults.videoMp4_480p.publicURL!}
                  type='video/mp4'
                  media='all and (max-width: 852px)'
                />
                <source
                  src={queryResults.videoWebm_720p.publicURL!}
                  type='video/webm'
                  media='all and (max-width: 1280px)'
                />
                <source
                  src={queryResults.videoMp4_720p.publicURL!}
                  type='video/mp4'
                  media='all and (max-width: 1280px)'
                />
                <source
                  src={queryResults.videoWebm_1080p.publicURL!}
                  type='video/webm'
                />
                <source
                  src={queryResults.videoMp4_1080p.publicURL!}
                  type='video/mp4'
                />
              </video>
            ) : (
              <img
                alt=''
                className='absolute inset-0 w-full h-full object-cover filter saturate-50'
                src={
                  queryResults.posterImage.childImageSharp!.gatsbyImageData
                    .images.fallback.src!
                }
              />
            )}
            <div className='absolute bg-brand-jawoodle-darker opacity-70 inset-0' />
          </div>
        ) : undefined}
      </React.Fragment>
    );
  };
}

export interface LayoutDefaultProps {
  useVideoBg: boolean;
}

export interface LayoutProps extends Partial<LayoutDefaultProps> {
  location: WindowLocation;
}

interface LayoutState {
  autoplaySupported: boolean;
}

interface LayoutQueryResults {
  videoMp4_480p: File;
  videoWebm_480p: File;
  videoMp4_720p: File;
  videoWebm_720p: File;
  videoMp4_1080p: File;
  videoWebm_1080p: File;
  posterImage: File;
}

const query = graphql`
  query {
    videoMp4_480p: file(
      relativePath: { eq: "videos/jawoodle_background_480p.mp4" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    videoWebm_480p: file(
      relativePath: { eq: "videos/jawoodle_background_480p.webm" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    videoMp4_720p: file(
      relativePath: { eq: "videos/jawoodle_background_720p.mp4" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    videoWebm_720p: file(
      relativePath: { eq: "videos/jawoodle_background_720p.webm" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    videoMp4_1080p: file(
      relativePath: { eq: "videos/jawoodle_background_1080p.mp4" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    videoWebm_1080p: file(
      relativePath: { eq: "videos/jawoodle_background_1080p.webm" }
      sourceInstanceName: { eq: "assets" }
    ) {
      publicURL
    }
    posterImage: file(
      relativePath: { eq: "images/jawoodle_background_poster.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          transformOptions: { fit: INSIDE }
          layout: FIXED
          formats: [AUTO]
        )
      }
    }
  }
`;
