// Check if window exists, as it doesn't in an SSR environment
export const IsBrowserEnvironment = !!(
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  document.body
);

export const IsSSREnvironment = !IsBrowserEnvironment;

export const DuringAnimationFrame = <T extends (...args: any[]) => void>(
  inputFn: T
): T => {
  let cancellationToken: number | undefined = undefined;

  return ((...args: any[]) => {
    if (cancellationToken) {
      window.cancelAnimationFrame(cancellationToken);
    }

    cancellationToken = window.requestAnimationFrame(() => inputFn(...args));
  }) as any as T;
};
